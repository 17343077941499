import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"session-item",class:{ selected: _vm.selected },attrs:{"data-id":_vm.session.ID},on:{"click":function($event){return _vm.$emit('click')}}},[_c(VDialog,{model:{value:(_vm.openFeedback),callback:function ($$v) {_vm.openFeedback=$$v},expression:"openFeedback"}},[_c(VCard,{staticClass:"centered-player",attrs:{"width":"50%","color":"#FFFFFF"}},[(
          _vm.session !== null && _vm.session.VideoFeedback !== null && _vm.openFeedback
        )?_c('VideoPlayer',{attrs:{"id":_vm.session.ID,"url":_vm.session.VideoFeedback.URI}}):_vm._e()],1)],1),_c('div',{staticClass:"line"}),_c(VCard,{staticClass:"position-relative pa-4",attrs:{"tile":"","elevation":"0"}},[(!_vm.session.Completed)?_c(VProgressLinear,{attrs:{"top":"","left":"","stream":"","absolute":"","height":"5px","buffer-value":_vm.progress,"color":"darkgray darken-4"}}):_vm._e(),_c('div',{staticClass:"text-body-1 font-weight-bold text-truncate"},[_c('span',[_vm._v(_vm._s(_vm.name))])]),_c('div',{staticClass:"text-subtitle-2 text-truncate"},[_c('span',[_vm._v(_vm._s(_vm._f("formatwithtime")(_vm.session.CreatedAt)))]),_c('span',{staticClass:"mx-2"},[_vm._v("·")]),(_vm.session.Completed)?_c('span',[_vm._v(_vm._s(_vm.length))]):_c('span',[_vm._v(_vm._s(_vm.statusText))])]),(!_vm.isOtherUser)?_c('div',{staticClass:"d-flex align-center mt-2"},[_c('div',{staticClass:"overline",staticStyle:{"line-height":"1.2"}},[_c('span',[_vm._v(_vm._s(_vm.sharedWith))])]),_c(VSpacer),_c('EditSessionDialog',{attrs:{"session":_vm.session,"selected":_vm.selected}}),_c('DeleteSession',{attrs:{"id":_vm.session.ID,"can-delete":true}})],1):_vm._e(),(_vm.session.VideoFeedback !== null)?_c('div',[_c(VBtn,{on:{"click":_vm.showFeedback}},[_c('div',[_vm._v("Feedback")]),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-play")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }