


















































































import Vue from "vue";
import api from "@/core/utils/api";
import { Action, Getter } from "vuex-class";
import { Component, Prop, Watch } from "vue-property-decorator";

import { Session } from "@/core/models/sessions";
import EditSharedWith from "./EditSharedWith.vue";
import DeleteSession from "@/components/sessions/DeleteSession.vue";

@Component({ components: { EditSharedWith, DeleteSession } })
export default class EditSessionDialog extends Vue {
  @Prop({ default: () => false }) selected!: boolean;
  @Prop({ default: () => ({} as Session) }) session!: Session;

  @Getter("groups/loadingGroups") groupsLoading!: boolean;
  @Getter("profile/getUserEmail") currentUserEmail!: string;
  @Action("sessions/update") updateSession!: (d: any) => void;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  name = "";
  form = false;
  loading = false;
  editDialog = false;
  get items() {
    return [
      { text: this.$t("sess.private").toString(), value: "private" },
      { text: this.$t("sess.shared").toString(), value: "shared" },
      { text: this.$t("sess.public").toString(), value: "public" },
    ];
  }

  showUsers = false;
  users: string[] = [];
  get count() {
    const str = this.users.join(", ");
    if (str.length > 0)
      return str.length < 30
        ? str
        : this.users.length > 1
        ? `${this.$t("sess.shared", { n: this.users.length }).toString()}`
        : `${this.$t("sess.sharedWithOne", {
            n: this.users.length,
          }).toString()}`;
    else return this.$t("sess.sharedWithNone").toString();
  }

  // Edit functionality
  @Watch("editDialog")
  editDialogChanged() {
    if (!this.editDialog) this.users = this.session.SharedWith.slice(0);
  }
  toggleEdit() {
    if (this.editDialog) {
      this.editDialog = false;
      this.users = this.session.SharedWith.slice(0);
    } else {
      this.name = this.session.Name;
      this.editDialog = true;
    }
  }
  cancel() {
    this.editDialog = false;
    this.users = this.session.SharedWith.slice(0);
  }
  async editSession() {
    if (this.name.length === 0) return;

    this.loading = true;
    try {
      const end = "/api/Sessions/Edit";
      const data = {
        name: this.name,
        id: this.session.ID,
        sharedWith: this.users,
      };
      await api.put(end, data);
      this.updateSession(data);
      const msg = this.$t("sess.updated").toString();
      this.displaySnackbar(msg);
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
    this.toggleEdit();
  }
  get hasChanges() {
    const usersChanged =
      this.users &&
      this.session.SharedWith &&
      this.users.sort().join("") !== this.session.SharedWith.sort().join("");
    const nameChanged = this.name !== this.session.Name;
    return usersChanged || nameChanged;
  }
  mounted() {
    if (this.session.SharedWith) this.users = this.session.SharedWith.slice(0);
  }
}
